.header-container{
    width: -webkit-fill-available;
    width: -moz-available;
    position: fixed;
    top: 0;
    z-index: 50;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px #a2a2a26b;
    padding: 0 1.2rem 0 1.2rem;
    height: 4.5rem;
    justify-content: space-between;

    .header-logo{
        text-decoration: none;
        outline: none;
        p{
            font-size: 14px;
            margin: 4px 0 0 6px;
            color: #0e1e24e0;
            font-weight: 700;
            background-color: #f9d86b;
            padding: 2px 4px;
            border-radius: 6px;
        }
    }
    .header-menu-r-btn{
        background-color: rgba(234, 244, 254, 0.712);
        margin-left: 1.2rem;
        border-radius: 50%;
        transition-duration: 0.2s;
    }
    .header-menu-r-btn:hover{
        background-color: #EAF4FE;
        cursor: pointer;
    }
    .header-pro-pic, .header-menu-r-btn{
        height: 45px;
        width: 45px;
    }
    .header-pro-pic{
        margin-left: 1.2rem;
    }
}