.utl-label{
    padding: 6px 10px 5px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 0.9rem;
    margin: 0;
    color: white;
    width: fit-content;
}
.utl-l-rnd-s{
    border-radius: 20px!important;
}