.utl-o-loader{
    background-color: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index: 999;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    circle{
        stroke: white!important;
        stroke-width: 4;
    }
}