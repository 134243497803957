.login-box{
    margin-top: 40px;

    .login-text{
        font-size: 22px;
        padding-bottom: 8px
    }
    .login-sub-text{
        color: rgb(194, 194, 194);
        font-size: 15px
    }
    .login-f-pass{
        color: #1A87FE;
        font-size: 15px;
        font-weight: 600
    }
    .login-btn-div{
        margin: 40px 0 10px 0;
        align-self: stretch
    }
}