.circular {
    animation: rotate 2s linear infinite;
    margin: 0 auto
}

.animate_loader .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    // animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
 
@keyframes rotate{
    100%{
        transform: rotate(360deg);
    }
}
 
@keyframes dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}
 
// @keyframes color{
//     100%, 0%{
//         stroke: #00000045;
//     }
//     50%{
//         stroke: #FF9300;
//     }
// }
