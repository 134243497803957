.sidebar-container{
    height: 100%;
    width: 380px;
    // background-color: #1A87FE;
    background-color: white;
    position: fixed;
    left: 0;
    box-shadow: -2px 0 6px #67676761;

    .sb-title-div{
        padding: 26px 35px 23px;
        background-color: #FDD235;
        justify-content: space-between;
        h3{
            opacity: 0.5;
        }
        .accnt-type-sclton{
            width: 90px;
            height: 27px;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.089);
        }
    }
    .sb-contents-div{
        flex-direction: column;
        justify-content: space-between;
        // height: calc(100vh - 148px);

        .sb-inner{
            padding: 30px 35px;
            h3{
                color: #000F6C
            }
        }

        svg{
            align-self: center;
            padding: 10px;
            height: 28px;
            opacity: 0.23;
        }
    }
}