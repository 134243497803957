.h-ddm-main{
    min-width: 300px;

    .h-ddm-profile-div{
        padding: 10px 20px;
        border-bottom: 1.3px solid rgba(0, 0, 0, 0.13);
        margin-bottom: 8px;
        
        h4{
            font-size: 17px;
            padding-bottom: 3px
        }
        .h-ddm-p-email{
            color: rgb(138, 138, 138);
            font-size: 15px
        }

        .profile-pic-big{
            width: 85px;
            height: 85px;
        }
    }
}