.auth-toggle-btn{
    padding: 6px 10px;
    border-radius: 50px;
    background-color: #1A87FE;
    margin-left: 5.7vw;
    color: rgb(255, 255, 255);
    font-size: 15px;
    outline: none;
    border: 0;
    font-weight: 600;
    transition: 0.2s
}
.auth-toggle-btn:hover{
    cursor: pointer;
}

.auth-input{
    margin-bottom: 15px;
    // width: calc(100% - 18px)
}