.dinfo-progress-div{
    // padding: 20px 0;
    padding-bottom: 30px;

    // .dinfo-progress-text
    h4{
        // padding-bottom: 5px;
        color: rgba(0, 0, 0, 0.555)
    }
}

.dinfo-conatiner{
    // background-color: rgb(243, 243, 243);
    // padding: 10px 0px;
    border-radius: 5px;
    table{
        width: 100%;
        border-collapse: collapse;
    }
    .dinfo-label{
        border-radius: 5px 0 0 5px;
        // font-weight: 600;
        color: rgba(0, 0, 0, 0.377);
        padding: 15px;
        font-size: 15.6px;
        background-color: #f7f7f7
    }
    .dinfo-val{
        border-radius: 0 5px 5px 0;
        font-weight: 600;
        padding-right: 15px;
        text-align: right;
        color: rgba(0, 0, 0, 0.507);
        background-color: #ececec
    }
}

// .dinfo-container{
//     background-color: rgba(0, 0, 0, 0.041);
//     border-radius: 5px;
//     padding: 10px;
// }

.sclton-sidebar{
    div{
        height: 45px;
        margin-bottom: 20px;
    }
}