.df-main{

    .df-data-container, .df-wb{
        flex-direction: column;
    }

    .df-wb{
        background-color: white;
        margin: 20px auto;
        width: fit-content;
        padding: 30px;
        // flex-direction: column;
        min-width: 400px;
        p{
            font-size: 15px;
            padding: 10px 0;
            color: #c2c2c2;
        }
        .df-wb-f-info{
            width: 100%
        }

        .df-err-box{
            width: 100%;
            margin: -10px;
            .err-svg{
                fill: #ff4343;
                width: 35px;
                height: 35px;
            }
            p{
                color:#ff4343;
                line-height: 19px;
                padding: 0;
                margin-left: 20px;
                font-weight: 600;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .df-wb{
            min-width: 77%;

            .df-err-box{
                .err-svg{
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    .df-botm-box{
        // padding: 20px 50px;
        justify-content: flex-end;

        .df-botm-r-box{
            background-color: white;
            padding: 20px 20px 20px 25px;
            margin: 10px;
            box-shadow: 0 4px 13px rgba(0, 0, 0, 0.295);

            svg{
                width: 195px;
                height: 160px;
            }
            @media only screen and (max-width: 768px) {
                svg{
                    width: 190px;
                    height: 155px;
                }
            }

            .detail-div{
                max-width: 220px;
                align-self: stretch;
                margin-left: 20px;
                flex-direction: column;
                justify-content: space-between;
                padding: 5px;

                h3{
                    opacity: 0.9;
                }
                .onegb-txt{
                    font-size: 15.3px;
                }
                font{
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.582);
                }
                .detail-txt{
                    color: #c2c2c2;
                    font-size: 15px;
                    line-height: 19px;
                    margin: 10px 0 10px
                }
                a{
                    align-self: flex-end;
                }
            }
        }
    }
}