.utl-profile-pic{
    border-radius: 50%;
    object-fit: cover;
}
.utl-profile-pic-btn{
    background-color: orange;
    border-radius: 50%;
    outline: none;
    border: 0;
    color: rgba(0, 0, 0, 0.712);
}
.utl-profile-pic-btn, .utl-profile-pic:hover{
    cursor: pointer;
}