.f-list-loader-div{
    justify-content: center;
    width: 100%;
}
.f-list-empty-screen{
    flex-direction: column;
    height: 17rem;
    opacity: 0.3;
    
    h4{
        font-size: 17px;
    }
    p{
        text-align: center;
        font-size: 13px;
        padding-top: 10px;
        line-height: 19px;
        opacity: 0.8;
    }
}