body{
    background-color: #EAF4FE;
    -webkit-font-smoothing: antialiased;
    font-family: sans-serif;
    margin: 0px;
}

.g-main-container{
    margin-left: 380px;
}

.g-flex{
    display: flex
}
.g-flex-ac{
    display: flex;
    align-items: center
}
.g-source-sans{
    font-family: 'Source Sans Pro', sans-serif;
}
.g-roboto{
    font-family: 'Roboto', sans-serif;
    margin: 0;
}
.p-color-yellow{
    background-color: #FDD235
}

.g-round-corner{
    border-radius: 5px
}
.g-skltn-bg-color{
    background-color: rgba(0, 0, 0, 0.041);
}

.g-white-box{
    background-color: white;
}

.g-input{
    border: 0;
    border-radius: 5px;
    font: small-caption	;
    color: #34495e;
    outline: none;
}

.profile-pic-common{
    border-radius: 50%;
    object-fit: cover;
}

//---loader skeleton
.g-sklton-line {
    // background-color: rgb(255, 255, 255);
    background-image: linear-gradient(
      to right,
      transparent 33%,
      rgb(255, 255, 255) 50%,
      transparent 66%
    );
    background-size:300% 100%;
    animation: g-shine 1.4s infinite;

    @keyframes g-shine {
      0% {
        background-position: right;    
      }
      /*100% {
        background-position: left; it's the default value, no need to define it
      }*/
    }
  }
  .g-sklton-color{
      background-color: rgba(0, 0, 0, 0.041)
  }



.g-blu-screen-fill{
    width: 100%;
    height: 100vh;

    input{
        width: calc(100% - 18px)
    }
    .g-sml-white-box{
        margin: auto auto;
        background-color: white;
        padding: 40px;
        // max-width: 361px;
        max-width: 345px;
    }
    h3{
        font-size: 22px;
        padding-bottom: 8px
    }
    .g-bsf-sub-text{
        color: rgb(194, 194, 194);
        font-size: 15px;
        line-height: 19px
    }
    .link{
        color: #1a87fe;
        font-weight: 600;
    }
}