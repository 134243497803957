.add-file-box{
    background-color: #1A87FE;
    padding: 25px 35px 25px 35px;

    @media only screen and (max-width: 768px) {
            width: fit-content;
    }

    .afb-c-box{
        width: 100%;

        .main-title{
            font-size: 18px;
            color: rgb(255, 255, 255);
            margin-bottom: 12px
        }
        .info-text{
            color: rgba(255, 255, 255, 0.842);
            font-size: 11.8px;
            margin: 10px 0 -12px;
            text-align: right;
        }
    }
}