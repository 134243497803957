.epn-conatiner{
    padding: 2px 12px 12px;
    flex-direction: column;

    .epn-ib-title{
        opacity: 0.4;
        margin-bottom: 10px
    }
    .epn-margin-b{
        margin-bottom: 15px;
    }
    .epn-sub-catagory{
        // flex: 0 0 50%;
        flex: 1 1;
    }

    .epn-plan-info-box{
        // background-color: #2742d7;
        // background-color: #3a53db;
        background-color: white;
        margin-bottom: 30px;
        padding: 20px 30px 20px 25px;
        justify-content: space-between;

        h3{
            // color: rgba(255, 255, 255, 0.918);
            color: rgba(0, 0, 0, 0.719);
            font-size: 20px;
            margin-left: 15px;
        }
    }

    .epn-info-box{
        background-color: white;
        padding: 40px 30px;
        flex-direction: column;
        align-items: flex-end;

        input{
            width: calc(100% - 18px)
        }

        .c-avatar-container{
            align-self: stretch;
            flex-direction: column;
            margin-bottom: 30px;
            .c-avatar{
                position: relative;
                height: 120px;
                svg{
                    position: absolute;
                    bottom: 5px;
                    right: -6px;
                    fill: rgba(0, 0, 0, 0.822);
                    padding: 8px;
                    background-color: #f0f0f0;
                    border-radius: 50%;
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.315);
                    transition-duration: 0.18s
                }
                svg:hover{
                    cursor: pointer;
                    background-color: rgb(243, 243, 243);
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.315);
                }
            }
            #file-input{
                display: none;
            }
        }
        .img-process-div{
            background-color: rgb(231, 231, 231);
            height: 120px;
            width: 120px;
            border-radius: 50%;
        }
    }
}