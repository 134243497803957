.btn-utl{
    min-width: 100px;
    min-height: 40px;
    color: #0000008c;
    border-radius: 5px;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    outline: none;
}
.btn-utl:hover{
    cursor: pointer;
}