.utl-input{
    background-color: #00000011;
    font-size: 16.5px;
    font-weight: 500;
    padding: 8px 8px;
    opacity: 0.8;
    transition-duration: 0.2s;
    border: 1px solid #00000000
}
.utl-input:focus, .utl-input:hover {
    opacity: 1;
}
::placeholder{
    font-weight: 400
}

.utl-input-err{
  border: 1px solid #cc0033;
  background-color: #ff000025
//   outline: none;
}