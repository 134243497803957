.ddm-main{
    $textColor: #212529a2;
    $textHoverColor: #212529;
    align-items: center;
    flex-direction: column;
    padding: 8px 0;

    svg{
        fill: $textColor;
        margin-right: 13px
    }

    .ddm-text{
        font-size: 15.5px;
        font-weight: 400;
        color: $textColor
    }
    .ddm-item {
        align-self: stretch;
        cursor: pointer;
        padding: 10px 13px;
        height: 28px;
    }
    .ddm-item:hover{
        background-color: #f8f9fa;
        svg{
            fill: $textHoverColor
        }
        p{
            color: $textHoverColor
        }
    }
}

.ddm-psm-main{
    padding: 20px;

    .ddm-psm-sub{
        align-self: stretch;
        justify-content: flex-end;
        margin-top: 2vh
    }
    .ddm-psm-input-icon{
        background-color: #0000001c;
        padding: 8px;
        margin: 0;
        border-radius: 5px 0 0 5px;
    }
}

.popc-main{
    margin: 25px;
    .popc-sub-div{
        margin-bottom: 25px;
        .popc-sub-text{
            margin-top: 3px;
            color: #212529a2;
            font-size: 14px
        }
        .popc-icon{
            width: 48px;
            height: 48px;
            fill: #ff4343;
            margin-left: -3px;
            margin-right: 15px
        }
    }
    .popc-btn-div{
        justify-content: flex-end
    }
}